import app from 'firebase'

const config = {
  apiKey: "AIzaSyBjFNdJFOIywhCrcE7YagmsthbBZpRpL4M",
  authDomain: "blockchain-fair.firebaseapp.com",
  databaseURL: "https://blockchain-fair.firebaseio.com",
  projectId: "blockchain-fair",
  storageBucket: "blockchain-fair.appspot.com",
  messagingSenderId: "936381398245"
}

class Firebase {
  constructor() {
    return app.initializeApp(config)
  }
}

export default Firebase
