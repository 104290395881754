import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Checkbox, Input, Button } from 'antd'
import { withRouter, match } from 'react-router-dom'
import { compose } from 'recompose'
import './index.less'
import imgLogo from './imgs/logo.png'
import { createRule } from '../../libs/validationUtils'
import GeneralForm from '../../components/GeneralForm/GeneralForm'
import FileUpload from '../../components/FileUpload/FileUpload'
import { createFormItemLayout } from '../../libs/antdUtils'
import MessageModal from '../../components/MessageModal/MessageModal'
import { FirebaseContext } from '../../components/Firebase'
import { removeEmpty } from '../../libs/commonUtils'

class ApplyView extends PureComponent {
  //2019.07.15-lucas:2019.8월 행사는 appVer : 2
  state = {
    introFile: null,
    whitePaperFile: null,
    businessFile: null,
    etcFile: null,
    isSubmitting: false,
    appVer: "2"
  }

  _normFile = e => {
    if (Array.isArray(e)) return e
    return e && e.fileList
  }

  _handleOnChangeFile = (key, e) => {
    // if (e.file.size > 20971520) {
    //   MessageModal({
    //     type: 'error',
    //     title: '용량 초과',
    //     content: '파일의 용량이 20MB를 초과합니다.'
    //   })
    // }
    if (e.file.status === 'done' || e.file.status === 'removed') {
      this.setState({ [key]: e })
    }
  }

  _handleBeforeUpload = (file, fileList) => {
    if (file.size > 20971520) {
      MessageModal({
        type: 'error',
        title: '용량 초과',
        content: '파일의 용량이 20MB를 초과합니다.'
      })
      return false
    }
  }

  _handleOnClickApply = () => {
    const { form, firebase, history, match } = this.props
    const { introFile, whitePaperFile, businessFile, etcFile } = this.state
    const { type } = match.params
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ isSubmitting: true })
        const key = Date.now()

        const uploadFile = type === 'applicants' ? fileType => {
          const storageRef = firebase.storage().ref()
          const fileRef = storageRef.child(`files/${key}/${fileType.file.name}`)
          return fileRef.put(fileType.file.originFileObj)
        } : () => Promise.resolve()

        const applicant = Object.assign({}, values)

        uploadFile(introFile)
          .then(res => {
            if (type === 'applicants') {
              applicant.introFile = res.metadata
              return uploadFile(whitePaperFile)
            } else {
              return Promise.resolve()
            }
          })
          .then(res => {
            if (type === 'applicants') {
              applicant.whitePaperFile = res.metadata
              //190813-lucas-사업계획서는 선택사항으로 변경
              if (businessFile && businessFile.file) {
                return uploadFile(businessFile)
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.resolve()
            }
          })
          .then(res => {
            if (type === 'applicants') {
              if (businessFile && businessFile.file) {
                applicant.businessFile = res.metadata
              }
              if (etcFile && etcFile.file) {
                return uploadFile(etcFile)
                  .then(res => {
                    applicant.etcFile = res.metadata
                  })
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.resolve()
            }
          })
          .then(() => {
            return firebase.database().ref(`${type}${this.state.appVer}/${key}`).set(removeEmpty(applicant))
          })
          .then(() => {
            MessageModal({
              type: 'success',
              title: '제출 완료',
              content: '성공적으로 완료되었습니다.',
              onOk: () => {
                history.push('/')
              }
            })
          })
          .catch(err => {
            MessageModal({
              type: 'error',
              content: err
            })
          })
          .finally(() => {
            this.setState({ isSubmitting: false })
          })
      }
    })
  }

  _getFields = () => {
    let fields = [
      {
        label: '소속명',
        name: 'groupName',
        option: {
          rules: createRule({ isRequired: true, min: 1, max: 50 }).minMaxRule
        },
        layout: createFormItemLayout(7, 17),
        element: (
          <Input
            placeholder='소속명 입력'
          />
        )
      },
      {
        label: '사업(회사) 소개',
        name: 'description',
        option: {
          rules: createRule({ isRequired: true, min: 1, max: 1000 }).minMaxRule
        },
        layout: createFormItemLayout(7, 17),
        element: (
          <Input.TextArea
            placeholder='사업(회사)에 대해 간략하게 300자 내외로 소개해주세요.'
            rows={5}
          />
        )
      },
      {
        label: '성명',
        name: 'name',
        option: {
          rules: createRule({ isRequired: true, min: 1, max: 30 }).minMaxRule
        },
        layout: createFormItemLayout(7, 17),
        element: (
          <Input
            placeholder='성명을 입력해주세요.'
          />
        )
      },
      {
        label: '이메일',
        name: 'email',
        option: {
          rules: createRule({ isRequired: true }).emailRule
        },
        layout: createFormItemLayout(7, 17),
        element: (
          <Input
            type='email'
            placeholder='성명을 입력해주세요.'
          />
        )
      },
      {
        label: '휴대폰 번호',
        name: 'mobile',
        option: {
          rules: createRule({ isRequired: true }).mobileRule
        },
        layout: createFormItemLayout(7, 17),
        element: (
          <Input
            type='tel'
            placeholder='휴대폰 번호를 번호만 입력해주세요.'
          />
        )
      }
    ]
    if (this.props.match.params.type === 'applicants') {
      fields = fields.concat([
        {
          label: '회사소개서 업로드',
          name: 'introFile',
          option: {
            rules: createRule({ isRequired: true }).noMoreRule,
            valuePropName: 'fileList',
            getValueFromEvent: this._normFile
          },
          layout: createFormItemLayout(7, 17),
          element: (
            <FileUpload
              onChange={e => this._handleOnChangeFile('introFile', e)}
              disabled={(this.state.introFile && this.state.introFile.fileList.length > 0)}
            // beforeUpload={this._handleBeforeUpload}
            />
          )
        },
        {
          label: '백서 업로드',
          name: 'whitePaperFile',
          option: {
            rules: createRule({ isRequired: true }).noMoreRule,
            valuePropName: 'fileList',
            getValueFromEvent: this._normFile
          },
          layout: createFormItemLayout(7, 17),
          element: (
            <FileUpload
              onChange={e => this._handleOnChangeFile('whitePaperFile', e)}
              disabled={(this.state.whitePaperFile && this.state.whitePaperFile.fileList.length > 0)}
            />
          )
        },
        {
          label: '회사 홈페이지',
          name: 'homepageUrl',
          option: {
            rules: createRule({ isRequired: true, min: 1, max: 200 }).urlRule
          },
          layout: createFormItemLayout(7, 17),
          element: (
            <Input
              placeholder='홈페이지 URL을 입력해주세요.'
            />
          )
        },
        {
          label: '사업계획서 업로드',
          name: 'businessFile',
          option: {
            rules: createRule({ isRequired: false }).noMoreRule,
            valuePropName: 'fileList',
            getValueFromEvent: this._normFile
          },
          layout: createFormItemLayout(7, 17),
          element: (
            <FileUpload
              onChange={e => this._handleOnChangeFile('businessFile', e)}
              disabled={(this.state.businessFile && this.state.businessFile.fileList.length > 0)}
            />
          )
        },
        {
          label: '그 외 참고자료 업로드',
          name: 'etcFile',
          option: {
            valuePropName: 'fileList',
            getValueFromEvent: this._normFile
          },
          layout: createFormItemLayout(7, 17),
          element: (
            <FileUpload
              onChange={e => this._handleOnChangeFile('etcFile', e)}
              disabled={(this.state.etcFile && this.state.etcFile.fileList.length > 0)}
            />
          )
        }
      ])
    }
    return fields
  }

  render() {
    const { form, match } = this.props
    return (
      <div className='container form' style={{ padding: '60px 0' }}>
        <div className='text-center' style={{ marginBottom: 36 }}>
          <img src={imgLogo} alt='logo' style={{ maxWidth: '80%' }} />
        </div>
        <Card style={{ marginBottom: 24 }}>
          <h2 className='title'>개인정보 수집 및 이용 동의</h2>
          <div className='gray-box'>
            (주)함샤우트는 고객의 개인정보보호를 소중하게 생각하고, 고객의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다. (주)함샤우트는 「개인정보보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」을 비롯한 모든 개인정보보호 관련 법률규정을 준수하고 있습니다.
          </div>
          <Form.Item>
            {
              form.getFieldDecorator('isAgreed', {
                rules: createRule({ isRequired: true, requiredMessage: '개인정보 수집 및 이용에 동의해주세요.' }).noMoreRule,
                valuePropName: 'checked'
              })(
                <Checkbox>
                  개인정보 수집 및 이용에 동의합니다.
                </Checkbox>
              )
            }
          </Form.Item>
          {(match.params.type === 'applicants') && (<h2 className='title' style={{ marginTop: 48 }}>발표 신청</h2>)}
          {(match.params.type === 'audiences') && (<h2 className='title' style={{ marginTop: 48 }}>참관 신청</h2>)}
          <GeneralForm
            fields={this._getFields()}
            form={form}
          />
        </Card>
        <div className='text-center'>
          <Button
            type='primary'
            size='large'
            style={{ width: 150, height: 45 }}
            onClick={this._handleOnClickApply}
            loading={this.state.isSubmitting}
          >
            제출
          </Button>
        </div>
      </div>
    )
  }
}

ApplyView.propTypes = {
  form: PropTypes.object.isRequired
}

const wrappedApplyView = props => (
  <FirebaseContext.Consumer>
    {
      firebase => <ApplyView {...props} firebase={firebase} />
    }
  </FirebaseContext.Consumer>
)


export default compose(Form.create({}), withRouter)(wrappedApplyView)
