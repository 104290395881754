import React from 'react';
import { Icon } from 'antd';
import LinesEllipsis from 'react-lines-ellipsis';

const News = ({ title, detail, link }) => {
    return (
        <div className='news-card'>
            <h1>
                <LinesEllipsis maxLine={2} text={title} />
            </h1>
            <p>
                <LinesEllipsis maxLine={5} ellipsis='...' trimRight text={detail} />
            </p>
            <a href={link} target='_blank' noreferrer noopener>
                <Icon type='right' /> 자세히 보기
      </a>
        </div>
    );
};

export default News;
