import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Upload, Button, Icon } from 'antd'

class FileUpload extends PureComponent {
  render () {
    const { onChange, disabled, aside, ...restProps } = this.props
    return (
      <Upload
        action='https://api.usit.co.kr/files/dummy'
        listType='picture'
        onChange={onChange}
        disabled={disabled}
        {...restProps}
      >
        <Button disabled={disabled} size='large'>
          <Icon type='upload' /> 파일 업로드
        </Button>
        {aside}
      </Upload>
    )
  }
}

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default FileUpload
