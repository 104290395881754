import validator from 'validator'

export const createRule = ({ isRequired, requiredMessage = '필수 항목입니다.', min, max, customValidator,
                             maxPeriod = Number.MAX_SAFE_INTEGER, minPeriod = Number.MIN_SAFE_INTEGER, periodUnit }) => ({
  nameRule: [
    {
      required: isRequired, message: requiredMessage || '이름을 입력해주세요.'
    }, {
      min: 1, max: 50, message: '50자 이하로 입력해주세요.'
    }
  ],
  mobileRule: [
    {
      required: isRequired, message: requiredMessage || '휴대폰번호를 입력해주세요.'
    }, {
      min: 10, max: 11, message: '휴대폰번호를 정확하게 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (!validator.isNumeric(value || '')) {
          callback('숫자만 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  phoneRule: [
    {
      required: isRequired, message: requiredMessage || '연락처를 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (!validator.isNumeric(value || '')) {
          callback('숫자만 입력해주세요.')
        } else if (!validator.isLength(value, { min: 9, max: 11 })) {
          callback('연락처를 정확하게 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  genderRule: [
    {
      required: isRequired, message: requiredMessage || '성별을 입력해주세요.'
    }
  ],
  passwordRule: [
    {
      required: isRequired, message: requiredMessage || '비밀번호를 입력해주세요.'
    }, {
      min: 6, max: 20, message: '6~20자리로 입력해주세요.'
    }, {
      validator: customValidator
    }
  ],
  emailRule: [
    {
      required: isRequired, message: requiredMessage || '이메일주소를 입력해주세요.'
    }, {
      type: 'email', message: '이메일주소 형식이 아닙니다.'
    }, {
      validator: customValidator
    }
  ],
  unsignedNumberRule: [
    {
      required: isRequired, message: requiredMessage
    }, {
      validator: (rule, value, callback) => {
        if (value && (!validator.isNumeric(String(value)) || Number(value) < 0)) {
          callback('0 이상의 숫자로 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  businessNoRule: [
    {
      required: isRequired, message: requiredMessage || '사업자등록번호를 입력해주세요.'
    }, {
      min: 12, max: 12, message: '10자리를 정확히 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (value && (!validator.isNumeric(String(value.replace(/-/gi, ''))) || Number(value) <= 0)) {
          callback('사업자등록번호를 정확히 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  minMaxRule: [
    {
      required: isRequired, message: requiredMessage
    }, {
      min, max, message: `${min}~${max}자리로 입력해주세요.`
    }
  ],
  accountNumberRule: [
    {
      required: isRequired, message: requiredMessage || '계좌번호를 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (value && (!validator.isNumeric(String(value.replace(/-/gi, ''))) || Number(value) <= 0)) {
          callback('계좌번호를 정확하게 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  residentNoHeadRule: [
    {
      required: isRequired, message: requiredMessage || '주민등록번호 앞자리를 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (value && (value.length !== 6 || !validator.isNumeric(value) || Number(value) <= 0)) {
          callback('주민등록번호 앞자리를 정확하게 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  residentNoTailRule: [
    {
      required: isRequired, message: requiredMessage || '주민등록번호 뒷자리를 입력해주세요.'
    }, {
      validator: (rule, value, callback) => {
        if (value && (value.length !== 7 || !validator.isNumeric(value) || Number(value) <= 0)) {
          callback('주민등록번호 뒷자리를 정확하게 입력해주세요.')
        } else {
          callback()
        }
      }
    }
  ],
  urlRule: [
    {
      required: isRequired, message: requiredMessage
    }, {
      pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      message: 'URL 형식으로 정확히 입력해주세요.'
    }
  ],
  customRule: [
    {
      required: isRequired, message: requiredMessage
    }, {
      validator: customValidator
    }
  ],
  noMoreRule: [
    {
      required: isRequired, message: requiredMessage
    }
  ],
})
