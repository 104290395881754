import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Table, Form, Input, Button, Tabs } from 'antd'
import { compose } from 'recompose'
import { FirebaseContext } from '../../components/Firebase'
import { convertMapToArr, downloadFromURI } from '../../libs/commonUtils'
import MessageModal from '../../components/MessageModal/MessageModal'
import SpinContainer from '../../components/SpinContainer/SpinContainer'
import { createFormItemLayout } from '../../libs/antdUtils'
import { createRule } from '../../libs/validationUtils'
import GeneralForm from '../../components/GeneralForm/GeneralForm'

class AdminView extends PureComponent {

  state = {
    isCertified: false,
    isInitializing: true,
    dataSource: null,
    audienceDataSource: null,
    appVer: null,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isCertified && this.state.isCertified) {
      this._fetchData()
    }
  }

  _fetchData = () => {
    const { firebase } = this.props;

    let applicants = 'applicants';
    let audiences = 'audiences';

    //console.log('appVer', this.state.appVer)
    if (this.state.appVer !== "1") {
      applicants = 'applicants' + this.state.appVer;
      audiences = 'audiences' + this.state.appVer;
    }

    firebase.database().ref(applicants).once('value')
      .then(snapshot => {
        const data = convertMapToArr(snapshot.val())
        this.setState({ dataSource: data.map(item => Object.assign({}, item, { key: item.id })), isInitializing: false })
      })
    firebase.database().ref(audiences).once('value')
      .then(snapshot => {
        const data = convertMapToArr(snapshot.val())
        this.setState({ audienceDataSource: data.map(item => Object.assign({}, item, { key: item.id })), isInitializing: false })
      })
  }

  _handleOnClickDownload = data => {
    const { firebase } = this.props
    const ref = firebase.storage().ref(data.fullPath)
    ref.getDownloadURL().then(url => {
      downloadFromURI(url, data.name)
    })
  }

  _handleOnClickDetail = value => {
    MessageModal({
      type: 'info',
      title: '사업(회사) 소개',
      content: value
    })
  }

  _getColumns = type => {
    const { activeTabKey } = this.state
    let result = [
      {
        title: '소속명',
        dataIndex: 'groupName',
        key: 'groupName',
        align: 'center'
      },
      {
        title: '사업(회사) 소개',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
        render: value => (
          <a onClick={() => this._handleOnClickDetail(value)} style={{ cursor: 'pointer' }}>보기</a>
        )
      },
      {
        title: '성명',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
      },
      {
        title: '이메일',
        dataIndex: 'email',
        key: 'email',
        align: 'center'
      },
      {
        title: '휴대폰 번호',
        dataIndex: 'mobile',
        key: 'mobile',
        align: 'center'
      }
    ]
    if (type === 'applicants') {
      result = result.concat([
        {
          title: '회사소개서',
          dataIndex: 'introFile',
          key: 'introFile',
          align: 'center',
          render: value => {
            return (
              <a
                onClick={() => this._handleOnClickDownload(value)}
                target='_blank'
                rel='noopener noreferrer'
              >
                열기
              </a>
            )
          }
        },
        {
          title: '백서',
          dataIndex: 'whitePaperFile',
          key: 'whitePaperFile',
          align: 'center',
          render: value => {
            return (
              <a
                onClick={() => this._handleOnClickDownload(value)}
                target='_blank'
                rel='noopener noreferrer'
              >
                열기
              </a>
            )
          }
        },
        {
          title: '사업계획서',
          dataIndex: 'businessFile',
          key: 'businessFile',
          align: 'center',
          render: value => {
            if (value) {
              return (
                <a
                  onClick={() => this._handleOnClickDownload(value)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  열기
              </a>
              )
            } else {
              return null
            }
          }
        },
        {
          title: '참고자료',
          dataIndex: 'etcFile',
          key: 'etcFile',
          align: 'center',
          render: value => {
            if (value) {
              return (
                <a
                  onClick={() => this._handleOnClickDownload(value)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  열기
                </a>
              )
            } else {
              return null
            }
          }
        },
      ])
    }
    return result
  }

  _handleOnClickSignIn = () => {
    const { form, match } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        if (values.password === 'ljy0000') {
          this.setState({ isCertified: true, appVer: match.params.appver })
        } else {
          MessageModal({
            type: 'error',
            content: '비밀번호가 일치하지 않습니다.'
          })
        }
      }
    })
  }

  render() {
    const { dataSource, isInitializing, isCertified, audienceDataSource } = this.state
    const { form } = this.props
    return (
      <div className='container' style={{ padding: 48 }}>
        <div style={{ backgroundColor: '#fff' }}>
          {
            !isCertified &&
            <div style={{ maxWidth: 300, marginRight: 'auto', marginLeft: 'auto', padding: '80px 0' }}>
              <GeneralForm
                fields={[
                  {
                    label: '비밀번호',
                    name: 'password',
                    option: {
                      rules: createRule({ isRequired: true }).noMoreRule
                    },
                    layout: createFormItemLayout(6, 18),
                    element: (
                      <Input
                        type='password'
                        onPressEnter={this._handleOnClickSignIn}
                      />
                    )
                  }
                ]}
                form={form}
              />
              <Button type='primary' size='large' onClick={this._handleOnClickSignIn} block>신청목록 조회</Button>
            </div>
          }
          {
            isCertified && isInitializing && <SpinContainer />
          }
          {
            isCertified && !isInitializing &&
            <Tabs defaultActiveKey='applicants'>
              <Tabs.TabPane tab='발표기업' key='applicants'>
                <Table
                  dataSource={dataSource}
                  columns={this._getColumns('applicants')}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='일반참여' key='audiences'>
                <Table
                  dataSource={audienceDataSource}
                  columns={this._getColumns('audiences')}
                />
              </Tabs.TabPane>
            </Tabs>
          }
        </div>
      </div>
    )
  }
}

AdminView.propTypes = {
  firebase: PropTypes.object.isRequired
}

const wrappedAdminView = props => (
  <FirebaseContext.Consumer>
    {
      firebase => <AdminView {...props} firebase={firebase} />
    }
  </FirebaseContext.Consumer>
)

export default compose(Form.create({}))(wrappedAdminView)
