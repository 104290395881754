import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './App.css';
import './App.less'

import { Home, Admin, Apply } from './routes'

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/admin'
            component={props => (
              <Redirect to={'/2/admin'} />
            )}
          />
          <Route exact path='/' component={Home} />
          <Route path='/apply/:type' component={Apply} />
          <Route path='/:appver/admin' component={Admin} />
        </Switch>
      </Router>
    );
  }
}

export default App;
