import { Modal } from 'antd'

export default ({ type, title, content, locale, onOk }) => {
  const realContent = type === 'err' ? content.toString ? content.toString() : content.message || content : content
  return Modal[type]({
    title: type === 'error' && !title ? '오류 발생' : title,
    content: realContent,
    okText: '확인', onOk
  })
}
