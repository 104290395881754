import React, { PureComponent } from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'

class GeneralForm extends PureComponent {
  render () {
    const { fields, form, ...props } = this.props
    return (
      <Form {...props}>
        {
          fields.map((field, idx) => !field.isNoDecorator ? (
            <Form.Item
              key={field.name}
              label={field.label}
              colon={false}
              extra={field.extra}
              required={field.showAsRequired}
              {...field.layout}
            >
              {
                form.getFieldDecorator(field.name, field.option)(
                  React.cloneElement(field.element, { size: field.size || 'large' })
                )
              }
              {field.help && <div className='help-text'>{field.help}</div>}
            </Form.Item>
          ) : React.cloneElement(field.element, { key: idx }))
        }
      </Form>
    )
  }
}

GeneralForm.propTypes = {
  fields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  size: PropTypes.string
}

export default GeneralForm
