export const SCREEN_SIZE = {
  XS: 768,
  SM: 992,
  MD: 1200
}

export const COLOR = {
  PRIMARY: '#ff18af',
  SECONDARY: '#673ab7',
  GREEN: '#87d068',
  RED: '#f50',
  SKY: '#2db7f5',
  BLUE: '#108ee9',
  YELLOW: '#faad14',
  GRAY: '#8e8e8e',
  LIGHT_GRAY: '#bfbfbf',
  WHITE_GRAY: '#f0f2f5'
}

export const BLOCK_RADIO_STYLE = {
  display: 'block',
  height: 30,
  lineHeight: '30px'
}
