import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { Row, Col, Card, Divider, Icon, Button } from 'antd'
import imgBanner from './imgs/mainbanner.png'
import imgBannerMobile from './imgs/mainbanner_mobile.png'

import imgHahmshout from './imgs/logo_hahmshout1.png'
import imgBitsonic from './imgs/logo_bitsonic.png'
import imgBclab from './imgs/logo_blockchainlab.png'
import imgLatoken from './imgs/logo_latoken.png'
import imgPiexgo from './imgs/logo_piexgo.png'
import imgVelic from './imgs/logo_velic.png'
import imgHanbitco from './imgs/logo_hanbitco.png'
import imgGate from './imgs/logo_gate.png'
import imgHillstone from './imgs/logo_hillstone.png'
import imgMna from './imgs/logo_mnacenter.png'
import imgIcon from './imgs/logo_icon.png'

import './index.less'
import { isScreenSize } from '../../libs/commonUtils'
import News from '../../components/News'

class HomeView extends PureComponent {

  componentDidMount() {
    this._initMap()
  }

  _handleOnClickApply = type => {
    const { history } = this.props
    history.push(`/apply/${type}`)
  }

  _initMap = () => {
    const init = () => {
      window.daum.maps.load(() => {
        const daum = window.daum
        const mapContainer = document.getElementById('map')
        try {
          const mapOption = {
            center: new daum.maps.LatLng(37.55108621021494, 126.9729124035517),
            level: 3
          }

          // 구글 지도 api 연동
          const map = new daum.maps.Map(mapContainer, mapOption)
          const geocoder = new daum.maps.services.Geocoder()
          geocoder.addressSearch('서울 마포구 마포대로 45', (result, status) => {
            if (status === daum.maps.services.Status.OK) {
              let coords = new daum.maps.LatLng(result[0].y, result[0].x)
              let marker = new daum.maps.Marker({
                map: map,
                position: coords
              })
              // let infowindow = new daum.maps.InfoWindow({
              //   content: `<div style='width: 150px; text-align: center; padding: 5px;'>${campaign.companyNm}</div>`
              // })
              // infowindow.open(map, marker)
              marker.setMap(map)
              map.setCenter(coords)
            }
          })
        } catch (err) {
          console.log('err', err)
        }
      })
    }
    if (window.daum) {
      init()
    } else {
      setTimeout(() => this._initMap(), 1000)
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=0cedd340d218e828b84a48f178ae35b2&libraries=services,clusterer,drawing&autoload=false"></script>
        </Helmet>
        <img src={!isScreenSize.xs() ? imgBanner : imgBannerMobile} alt='banner' style={{ width: '100%', marginBottom: 24 }} />
        <div className='container'>
          <Row gutter={isScreenSize.xs() ? 0 : 24}>
            <Col md={24}>
              <Card style={{ textAlign: 'left' }}>
                <h3 className='title'>행사 소개</h3>
                <p>
                  스타트업 액셀러레이터이자 국내 최대 마케팅 대행사 선두주자 함샤우트가 비즈니스 고도화 및 마케팅 컨설팅을 통해 유망 블록체인 프로젝트를 양성하고,
                  산업 발전에 기여하고자 2019년 상반기에 이어 '2019 하반기 벤처 스타트업 캐스팅 페어'를 주최합니다.
                </p>
                <p style={{ marginTop: "20px" }}>
                  블록체인 프로젝트, 투자사, 거래소, 액셀러레이터 모두가 한 자리에 모여 '상생'을 도모하고
                  선순환을 이루는 협력의 장, 벤처스타트업 캐스팅 페어에 여러분을 초대합니다.<br />
                </p>
                <Divider className='bottom-divider' />
              </Card>
            </Col>
          </Row>
          <Row gutter={isScreenSize.xs() ? 0 : 24} style={{ marginTop: 24 }}>
            <Col md={24}>
              <Card>
                <h3 className='title'>
                  행사 개요
                </h3>
                <p>일시: 2019년 8월 28일(수) 16:00 ~ 18:30 (15:30 접수 시작)</p>
                <p>장소: 서울시 마포구 마포대로 45 일진빌딩 9층 (마포역 2번 출구)</p>
                <p>주최: 함샤우트, 블록체인투자연구소</p>
                <p>후원: BITSONIC, GATE, 한국M&A센터, HANBITCO, HILLSTONE PARTNERS, ICON FOUNDATION, LATOKEN, PIEXGO, VELIC</p>
                <div id='map' className="hahm-map" />
                <Divider className='bottom-divider' />
              </Card>
              <Card style={{ marginTop: 24 }}>
                <h3 className='title'>
                  행사 참가자 모집 기간
                </h3>
                <p>발표 기업 신청 마감: 2019년 8월 16일(금)</p>
                <p>선정 기업 개별 통보: 2019년 8월 21일(수)</p>
                <p>참관 신청 마감:  2019년 8월 25일(일)</p>
                <Divider className='bottom-divider' />
              </Card>
              <Card className='time-table' style={{ marginTop: 24 }}>
                <h3 className='title'>행사 프로그램</h3>
                <Row className='table-header'>
                  <Col span={6} className='col'>
                    시간
                  </Col>
                  <Col span={18} className='col'>
                    순서
                  </Col>
                </Row>
                {
                  [
                    {
                      time: '15:30 ~ 16:00',
                      content: '등록'
                    },
                    {
                      time: '16:00 ~ 16:05',
                      content: '환영사 - 함샤우트 김재희 대표'
                    },
                    {
                      time: '16:05 ~ 16:20',
                      content: '키노트 스피치 - 송인규 블록체인투자연구소장'
                    },
                    {
                      time: '16:20 ~ 17:20',
                      content: "블록체인 프로젝트 발표 - 6개 사"
                    },
                    {
                      time: '17:20 ~ 17:40',
                      content: '함샤우트 비즈니스 액셀러레이션 사례 발표'
                    },
                    {
                      time: '17:40 ~ 18:30',
                      content: '1:1 개별 미팅'
                    },
                  ].map(item => (
                    <Row key={item.time} className='table-row'>
                      <Col span={6} className='col time-col'>
                        {item.time}
                      </Col>
                      <Col span={18} className='col'>
                        {item.content}
                      </Col>
                    </Row>
                  ))
                }
                <Divider className='bottom-divider' style={{ marginTop: 27 }} />
              </Card>
              <Card style={{ marginTop: 24 }}>
                <h3 className='title'>
                  벤처 스타트업 캐스팅 페어 in MEDIA
                </h3>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <News
                      title={`건실한 블록체인 프로젝트 '캐스팅'해 제대로 키워볼게요`}
                      detail={`블록체인은 4차 산업혁명의 큰 흐름에서 가야 할 길이에요. 이견이 별로 없을 겁니다. 관건은 ‘어떻게’죠. 블록체인이 꼭 필요한 분야에, 잘할 수 있는 프로젝트들이 나서고, 거기에 제대로 판단·투자하는 생태계가 만들어져야 ...`}
                      link='https://www.hankyung.com/it/article/201903108639g'
                    />
                  </Col>
                  <Col xs={24} lg={8}>
                    <News
                      title={`함샤우트, 벤처 스타트업 캐스팅 페어 개최`}
                      detail={`함샤우트(대표 김재희·함시원)와 한국M&A센터(대표 유석호)는 다음달 26일 '2019 벤처 스타트업 캐스팅 페어'를 개최한다고 밝혔다. 행사를 통해 발굴한 유망 블록체인 프로젝트는 향후 투자유치는 물론 홍보마케팅, 거래소 상장 지...`}
                      link='http://www.etnews.com/20190226000321'
                    />
                  </Col>
                  <Col xs={24} lg={8}>
                    <News
                      title={`‘2019 벤처 스타트업 캐스팅 페어’ 성황리 마무리`}
                      detail={`스타트업 액셀러레이터이자 마케팅 전문가 그룹 함샤우트(공동대표 김재희·함시원)와 투자 매칭 플랫폼 한국M&A센터(대표 유석호)가 주최한 ‘2019 벤처 스타트업 캐스팅 페어’ 행사가 업계 관계자 150여명이 참석한 가운데 성황...`}
                      link='http://www.datanet.co.kr/news/articleView.html?idxno=132390'
                    />
                  </Col>
                </Row>
              </Card>
              <Card className='text-center' style={{ marginTop: 24, backgroundColor: '#b8c0e6', paddingTop: 23, paddingBottom: 23 }}>
                <Button type='primary' disabled className='apply-btn' style={{ width: 240, height: 72, marginBottom: 12 }} onClick={() => this._handleOnClickApply('applicants')}><span style={{ fontSize: "20px" }}>발표 신청하기</span><br />2019년 8월 16일(금)까지</Button>
                <Button type='primary' block style={{ width: 240, height: 72 }} onClick={() => this._handleOnClickApply('audiences')}><span style={{ fontSize: "20px" }}>참관 신청하기</span><br />2019년 8월 25일(일)까지</Button>
              </Card>
              <Card style={{ marginTop: 24, marginBottom: 24 }}>
                <h3 className='title'>문의처</h3>
                <p><Icon type='caret-right' className='item-icon' /> 대표 이메일 ICOPR@hahmshout.com</p>
                <p><Icon type='caret-right' className='item-icon' /> 이사 김지연 kjy1@hahmshout.com 02-6020-0560</p>
                <p><Icon type='caret-right' className='item-icon' /> 대리 이지윤 ljy@hahmshout.com 02-6020-0583</p>
                <Divider className='bottom-divider' />
              </Card>
              <Card style={{ marginTop: 24 }}>
                <h3 className='title'>주최</h3>
                <Row gutter={24}>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="http://hahmshout.com" target="_blank"><img src={imgHahmshout} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="http://www.bcapitalist.com/" target="_blank"><img src={imgBclab} style={{ width: "140px" }} /></a>
                  </Col>
                </Row>
                <h3 className='title'>후원</h3>
                <Row gutter={24}>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://bitsonic.co.kr/" target="_blank"><img src={imgBitsonic} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://www.gate.io/" target="_blank"><img src={imgGate} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="http://mnacenter.com/" target="_blank"><img src={imgMna} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://www.hanbitco.com/" target="_blank"><img src={imgHanbitco} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://www.hillstonepe.co/" target="_blank"><img src={imgHillstone} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://icon.foundation/" target="_blank"><img src={imgIcon} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href=" https://latoken.com/" target="_blank"><img src={imgLatoken} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://www.piexgo.com/" target="_blank"><img src={imgPiexgo} style={{ width: "140px" }} /></a>
                  </Col>
                  <Col xs={11} md={4} style={{ marginBottom: '24px' }}>
                    <a href="https://www.velic.io/" target="_blank"><img src={imgVelic} style={{ width: "140px" }} /></a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* <Row gutter={isScreenSize.xs() ? 0 : 24}>
            <Col md={12} >
              <Card style={{ marginTop: 24, marginBottom: 24 }}>
                <h3 className='title'>문의처</h3>
                <p><Icon type='caret-right' className='item-icon' /> 대표 이메일 ICOPR@hahmshout.com</p>
                <p><Icon type='caret-right' className='item-icon' /> 이사 김지연 kjy1@hahmshout.com 02-6020-0560</p>
                <p><Icon type='caret-right' className='item-icon' /> 대리 이지윤 ljy@hahmshout.com 02-6020-0583</p>
                <Divider className='bottom-divider' />
              </Card>
            </Col>
            <Col md={12}>
              <Card className='text-center' style={{ marginTop: 24 }}>
                <img src={imgLogo} alt='logo' />
              </Card>
            </Col>
          </Row> */}
        </div >
        <div className='footer'>
          ©2019 HAHM SHOUT Co., Ltd. All Rights Reserved.
        </div>
      </div >
    )
  }
}

HomeView.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(HomeView)
