import { forEach } from 'lodash'
import { SCREEN_SIZE } from '../constants/styles'

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export const convertMapToArr = map => {
  const arr = []
  forEach(map, (value, key) => {
    value.id = key
    arr.push(value)
  })
  return arr
}

export const downloadFromURI = (uri, fileName) => {
  const link = document.createElement('a')
  link.download = fileName
  link.target = "_blank"
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const isScreenSize = {
  xs: () => window.innerWidth < SCREEN_SIZE.XS,
  sm: () => window.innerWidth < SCREEN_SIZE.SM && window.innerWidth >= SCREEN_SIZE.XS,
  md: () => window.innerWidth < SCREEN_SIZE.MD && window.innerWidth >= SCREEN_SIZE.SM,
  lg: () => window.innerWidth >= SCREEN_SIZE.MD,
  smallerThan: (size) => {
    if (size === 'sm') {
      return window.innerWidth < SCREEN_SIZE.SM
    } else if (size === 'md') {
      return window.innerWidth < SCREEN_SIZE.MD
    } else {
      return window.innerWidth < size
    }
  },
  largerThan: (size) => {
    if (size === 'xs') {
      return window.innerWidth >= SCREEN_SIZE.XS
    } else if (size === 'sm') {
      return window.innerWidth >= SCREEN_SIZE.SM
    } else if (size === 'md') {
      return window.innerWidth >= SCREEN_SIZE.MD
    } else {
      return window.innerWidth > size
    }
  }
}
